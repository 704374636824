const Button = ({
  onClick,
  rounded = false,
  children,
}) => {
  return (
    <button
      className={
        "button " +
        (rounded ? "rounded " : "")
      }
      onClick={() => onClick()}
    >
      {children}
    </button>
  );
};

export default Button;
