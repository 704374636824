const NavBar = () => {
  return (
    <div className="component-navbar">
      <div className="navigation">
        <a href="https://1out100.com" className="mint-btn">
          HOME
        </a>
      </div>
    </div>
  );
};

export default NavBar;
