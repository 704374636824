import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import Mint from "./pages/mint";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import MetamaskProvider from "./components/MetamaskProvider";
import WhiteList from "./pages/whitelist";

function App() {
  function getLibrary(provider) {
    return new Web3Provider(provider);
  }

  return (
    <div className="App">
      <Web3ReactProvider getLibrary={getLibrary}>
        <MetamaskProvider>
          <Router>
            <Routes>
              <Route path="/" element={<Mint />} />
              <Route path="/whitelist" element={<WhiteList />} />
            </Routes>
          </Router>
        </MetamaskProvider>
      </Web3ReactProvider>
    </div>
  );
}

export default App;
