import { useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { toast, ToastContainer } from 'react-toastify';
import { AiFillMinusCircle } from 'react-icons/ai';
import { BsFillPlusCircleFill } from 'react-icons/bs';

import {
  Abi,
  contractAddress,
  hasRoleAdmin,
  connect,
  disconnect,
  getClaimCondition,
  updateClaimConditions,
} from '../../web3';
import { ethers } from 'ethers';

const keccak256 = require('keccak256');
const { MerkleTree } = require('merkletreejs');

const WhiteList = () => {
  const { active, account, chainId, error, activate, deactivate } =
    useWeb3React();
  const [contract, setContract] = useState(null);
  const [claimCondition, setClaimCondition] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);

  const [whitelist, setWhitelist] = useState([]);
  const addressInputRef = useRef(null);

  const loadAdmin = useCallback(async () => {
    if (active) {
      if (chainId !== '250') {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0xfa' }],
        });
      }
      const _contract = new ethers.Contract(
        contractAddress,
        Abi,
        new ethers.providers.Web3Provider(window.ethereum).getSigner()
      );
      setContract(_contract);
      const _isAdmin = await hasRoleAdmin(_contract, account);
      setIsAdmin(_isAdmin);
      const _condition = await getClaimCondition(_contract);
      setClaimCondition(_condition);
    }
  }, [account, active, chainId]);

  const handleConnectWallet = () => {
    if (active) {
      disconnect(deactivate);
    } else {
      connect(activate);
    }
  };

  const addToWhitelist = () => {
    if (addressInputRef.current.value === '') {
      toast.warn('not address');
      return;
    }
    if (whitelist.includes(addressInputRef.current.value)) {
      toast.warn('that address is already on whitelist');
      return;
    } else {
      setWhitelist([...whitelist, addressInputRef.current.value]);
      addressInputRef.current.value = '';
    }
  };

  const removeFromWhitelist = (address) => {
    setWhitelist(whitelist.filter((list) => list !== address));
  };

  const onSetMerkleRootWL = () => {
    if (!whitelist) {
      toast.warn('invalid data');
      return;
    }
    const hashedAddresses = whitelist.map((addr) => keccak256(addr));
    const merkleTree = new MerkleTree(hashedAddresses, keccak256, {
      sortPairs: true,
    });
    const root = merkleTree.getHexRoot();
    const id = toast.loading('Transaction pending');
    const condition = [
      {
        currency: claimCondition.currency,
        maxClaimableSupply: claimCondition.maxClaimableSupply,
        merkleRoot: root,
        pricePerToken: claimCondition.pricePerToken,
        quantityLimitPerTransaction: claimCondition.quantityLimitPerTransaction,
        startTimestamp: claimCondition.startTimestamp,
        supplyClaimed: claimCondition.supplyClaimed,
        waitTimeInSecondsBetweenClaims:
          claimCondition.waitTimeInSecondsBetweenClaims,
      },
    ];

    updateClaimConditions(contract, condition)
      .then((res) => {
        axios
          .post(
            'https://one-time-node-backend.netlify.app/.netlify/functions/api/update',
            whitelist
          )
          .then((res) => {
            console.log(res);
          });
        toast.update(id, {
          render: 'Successfully Whitelist Changed',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.code === 4001) {
          toast.update(id, {
            render: 'MetaMask Tx Signature: User denied transaction signature.',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          toast.update(id, {
            render: error?.data?.message || 'Error',
            type: 'error',
            isLoading: false,
            autoClose: 3000,
          });
        }
      });
  };

  useEffect(() => {
    loadAdmin();
  }, [loadAdmin]);

  useEffect(() => {
    // axios.get("http://localhost:4000/whitelist")
    axios.get('https://mint-button.herokuapp.com/whitelist').then((res) => {
      setWhitelist(
        res.data.map((item, index) => {
          return item.address;
        })
      );
    });
  }, []);

  return (
    <div className="page-admin">
      <ToastContainer
        className="text-white"
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {!active && (
        <div className="flex justify-center items-center">
          <button className="connect-btn" onClick={handleConnectWallet}>
            {error instanceof UnsupportedChainIdError
              ? 'wrong network'
              : 'Connect Wallet'}
          </button>
        </div>
      )}
      {active ? (
        claimCondition ? (
          isAdmin ? (
            <div className="whitelist">
              <div className="add-field">
                <input ref={addressInputRef} />
                <button className="add-btn" onClick={() => addToWhitelist()}>
                  <BsFillPlusCircleFill />
                </button>
              </div>
              <div className="content">
                {whitelist?.map((data, index) => (
                  <div className="address-line" key={index}>
                    <div className="address"> {data}</div>
                    <div
                      className="remove-btn"
                      onClick={() => removeFromWhitelist(data)}
                    >
                      <AiFillMinusCircle />
                    </div>
                  </div>
                ))}
              </div>
              <button
                className="confirm-btn"
                onClick={() => onSetMerkleRootWL()}
              >
                {'Confirm'}
              </button>
            </div>
          ) : (
            <div className="text">You are not admin</div>
          )
        ) : (
          <div className="text">Loading...</div>
        )
      ) : (
        ''
      )}
    </div>
  );
};

export default WhiteList;
